<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="title mr-auto">
            <h4 class="title">Payroll</h4>
          </div>

          <form class="search mr-3" @submit.prevent="onSearch">
            <input
              type="text"
              v-model="search"
              id="searchbox"
              @keyup="searchCheck"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form>

          <!-- <button class="btn-add" @click="generatePdf">Export</button> -->
            <button class="btn-blue mr-2" v-if="enableSync" type="button" @click="getSync" :style="{
              padding: '8px 12px!important',
              borderRadius: '5px!important',
              border: '1px solid #248cf0',
              fontSize: '13px'
            }">
              <img src="/img/icon-sync.svg" alt="" /> Data Sync
            </button>
          <button
            v-if="
              checkAccess('payroll') || checkAccess('payroll', 'cru_payroll')
            "
            class="btn-add"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            + Tambah
          </button>
          <div class="dropdown-menu text-center">
            <router-link
              class="btn-gaji dropdown-item"
              to="/data-payroll/tambah-gaji"
              >Gaji</router-link
            >
            <router-link
              class="btn-bonus dropdown-item"
              to="/data-payroll/tambah-bonus"
              >Bonus</router-link
            >
            <router-link
              class="btn-thr dropdown-item"
              to="/data-payroll/tambah-thr"
              >THR</router-link
            >

            <router-link
              class="btn-man-power dropdown-item"
              to="/data-payroll/tambah-man-power"
              >Man Power</router-link
            >
          </div>
        </div>
        <div class="card-table">
          <div class="d-flex mb-4 align-items-center">
            <Select2
              v-model="catageoryFilter"
              :options="optionCategory"
              placeholder="Pilih Kategori"
              class="mr-3"
              style="width: 250px"
              @change="getList()"
              @select="getList()"
            />
            <!-- <Datepicker
              v-model="dateFilter"
              :enableTimePicker="false"
              :format="dateFormat"
              style="width: 330px"
              selectText="Pilih"
              cancelText="Batal"
              class="mr-3"
              @update:modelValue="setDate($event)"
              @clear="setDate(null)"
              range
            /> -->
            <Datepicker
              v-model="monthFilter"
              selectText="Pilih"
              cancelText="Batal"
              style="width: 250px"
              :enableTimePicker="false"
              :format="monthFormat"
              monthPicker
              placeholder="Pilih Bulan"
              @update:modelValue="setMonth($event)"
              @clear="setMonth(null)"
            />
          </div>
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tablePayrollList"
            >
              <thead>
                <tr>
                  <th style="width: 15%">Payroll</th>
                  <th style="width: 12%">Bulan</th>
                  <th style="width: 10%">Tipe Bayar</th>
                  <th style="width: 12%">Tanggal Bayar</th>
                  <th style="width: 5%">Kode Project</th>
                  <th style="width: 8%">Pembayaran</th>
                  <th style="width: 5%" class="text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in list" :key="index">
                  <td
                    :class="{
                      'text-uppercase': value.kategori == 'thr',
                      'text-capitalize':
                        value.kategori == 'gaji' ||
                        value.kategori == 'bonus' ||
                        value.kategori == 'man power',
                    }"
                  >
                    {{ value.kategori }}
                  </td>
                  <td>{{ formatMonth(value.bulan) }}</td>
                  <td class="text-capitalize">{{ value.tipe_bayar }}</td>
                  <td>{{ formatDate(value.tanggal_bayar) }}</td>
                  <td>{{ value.kode_project ? value.kode_project : "-" }}</td>
                  <td>
                    <div
                      class="green-bedge"
                      v-if="value.status_pembayaran == 'lunas'"
                    >
                      Lunas
                    </div>
                    <div class="red-bedge text-capitalize" v-else>
                      {{ value.status != "aktif" ? value.status : "Belum" }}
                    </div>
                  </td>
                  <td class="text-center">
                    <svg
                      v-on:click="detail(value)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Lihat Detail"
                      class="edit-icon"
                      width="27"
                      height="27"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.74935 2.58203V1.29036V2.58203ZM5.16602 5.16536H3.87435H5.16602ZM5.16602 25.832H3.87435H5.16602ZM18.0827 1.29036H7.74935V3.8737H18.0827V1.29036ZM7.74935 1.29036C6.72164 1.29036 5.73601 1.69862 5.00931 2.42533L6.836 4.25202C7.07824 4.00978 7.40678 3.8737 7.74935 3.8737V1.29036ZM5.00931 2.42533C4.28261 3.15203 3.87435 4.13765 3.87435 5.16536H6.45768C6.45768 4.82279 6.59377 4.49425 6.836 4.25202L5.00931 2.42533ZM3.87435 5.16536V25.832H6.45768V5.16536H3.87435ZM3.87435 25.832C3.87435 26.8597 4.28261 27.8454 5.00931 28.5721L6.836 26.7454C6.59377 26.5031 6.45768 26.1746 6.45768 25.832H3.87435ZM5.00931 28.5721C5.73601 29.2988 6.72163 29.707 7.74935 29.707V27.1237C7.40678 27.1237 7.07824 26.9876 6.836 26.7454L5.00931 28.5721ZM7.74935 29.707H23.2493V27.1237H7.74935V29.707ZM23.2493 29.707C24.2771 29.707 25.2627 29.2988 25.9894 28.5721L24.1627 26.7454C23.9205 26.9876 23.5919 27.1237 23.2493 27.1237V29.707ZM25.9894 28.5721C26.7161 27.8454 27.1243 26.8597 27.1243 25.832H24.541C24.541 26.1746 24.4049 26.5031 24.1627 26.7454L25.9894 28.5721ZM27.1243 25.832V5.16537H24.541V25.832H27.1243ZM23.2493 1.29036H18.0827V3.8737H23.2493V1.29036ZM27.1243 5.16537C27.1243 3.02526 25.3895 1.29036 23.2493 1.29036V3.8737C23.9627 3.8737 24.541 4.452 24.541 5.16537H27.1243Z"
                        fill="#898989"
                      />
                      <path
                        d="M21.9583 15.5H15.5"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3327 15.5H9.04102"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.9583 21.957H15.5"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3327 21.957H9.04102"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.9583 9.04297H18.7292H15.5"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3327 9.04297H9.68685H9.04102"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
// import { jsPDF } from "jspdf";
import Datepicker from "vue3-date-time-picker";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
// cksClient
import $ from "jquery";
import { cksClient, checkModuleAccess, checkApp, showLoading, showAlert } from "../../../helper";
import { get_PayrollList } from "../../../actions/payroll";
import moment from "moment";
import "moment/locale/id";
import { post_SyncTable } from '../../../actions/integrations';

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
  },
  data() {
    return {
      enableSync: checkApp('rawuh') ? true : false,
      checkAccess: checkModuleAccess,
      list: [],
      isSearch: false,
      search: "",
      id_company: cksClient().get("_account").id_company,
      dataTable: "",
      catageoryFilter: "all",
      optionCategory: [
        { id: "all", text: "Semua" },
        { id: "gaji", text: "Gaji" },
        { id: "thr", text: "THR" },
        { id: "bonus", text: "Bonus" },
        { id: "man power", text: "Man Power" },
      ],
      dateFilter: [],
      monthFilter: [],
    };
  },

  mounted() {
    // this.tooltip();
    // this.exportPdf();
  },

  created() {
    this.getList();
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },

  methods: {
    // generatePdf() {
    //   const doc = new jsPDF();

    //   doc.text("Hello World!", 20, 20);

    //   var iframe = document.getElementById("pdfFrame");
    //   iframe.src = doc.output("bloburi");
    // },

    setMonth($event) {
      this.monthFilter = $event ? $event : [];
      this.getList();
    },

    setDate($event) {
      this.dateFilter =
        $event && $event[0]
          ? [
              moment($event[0]).format("YYYY-MM-DD"),
              moment($event[1]).format("YYYY-MM-DD"),
            ]
          : [];
      console.log(this.dateFilter);
      this.getList();
    },

    dateFormat(date) {
      return `${moment(date[0]).format("DD MMM YYYY")} - ${moment(
        date[1]
      ).format("DD MMM YYYY")}`;
    },

    monthFormat(date, format = "MMMM YYYY") {
      var string = date.year + "-" + (date.month + 1) + "-01";
      return `${moment(string).format(format)}`;
    },

    formatMonth(string) {
      return moment(string + "-01").format("MMMM YYYY");
    },

    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(payroll) {
      const getPayroll = payroll.kategori;
      const getId = payroll.id;
      $('[data-toggle="tooltip"]').tooltip("hide");
      if (getPayroll == "bonus") {
        this.$router.push({
          name: "DetailBonus",
          params: {
            id: getId,
          },
        });
      }
      if (getPayroll == "gaji") {
        this.$router.push({
          name: "DetailSalary",
          params: {
            id: getId,
          },
        });
      }
      if (getPayroll == "thr") {
        this.$router.push({
          name: "DetailTHR",
          params: {
            id: getId,
          },
        });
      }
      if (getPayroll == "man power") {
        this.$router.push({
          name: "DetailManPower",
          params: {
            id: getId,
          },
        });
      }
    },

    searchCheck: function () {
      if (!this.search && this.isSearch) {
        this.isSearch = false;
        this.getList();
      }
    },
    async onSearch() {
      if (this.search || this.isSearch) {
        this.isSearch = true;
        this.getList();
      }
      if (!this.search && this.isSearch) {
        this.isSearch = false;
      }
    },
    async getList() {
      await get_PayrollList(
        {
          id_company: this.id_company,
          search: this.search,
          kategori: this.catageoryFilter,
          range_date: this.dateFilter,
          bulan:
            this.monthFilter.year && this.monthFilter.month >= 0
              ? this.monthFormat(this.monthFilter, "YYYY-MM")
              : "",
        },
        (res) => {
          this.list = [];
          this.list = res.list;
          if (this.dataTable) {
            this.dataTable.destroy();
          }
          $(".tooltip.fade.show").remove();
          setTimeout(() => {
            this.tooltip();
            this.dataTable = $("#tablePayrollList").DataTable({
              info: false,
              language: {
                emptyTable: "Tidak ada data",
              },
              order: [],
            });
          }, 1000);
        }
      );
    },

    getSync() {
      var show = showLoading(this.$swal);
      post_SyncTable(
        {
          id_company: cksClient().get("_account").id_company,
          table: "payroll",
        },
        () => {
          this.getList();
          setTimeout(() => {
            show.close();
            showAlert(this.$swal, {
              title: "SUCCESS!",
              msg: "Data telah disinkronkan",
              confirmButtonText: "Tutup",
            });
          }, 500);
        },
        () => {
          show.close();
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: `Terjadi kesalahan, data tidak dapat disinkronkan`,
            cancelButtonText: "Tutup",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
  },
};
</script>

<style scoped>
.card-table {
  margin-top: 0px !important;
}

.btn-gaji {
  background-color: #68caf8;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.btn-bonus {
  background-color: #f8ce3a;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.btn-thr {
  background-color: #ff5252;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.btn-man-power {
  background-color: #00c8bc;
  color: #fff;
  padding: 7px 12px !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.dropdown-menu {
  background: transparent !important;
  box-shadow: none !important;
  width: 102px !important;
  min-width: unset;
}
.dropdown-item:nth-child(2) {
  margin-top: 6px;
}

.dropdown-item:nth-child(3) {
  margin-top: 6px;
}

.dropdown-item:nth-child(4) {
  margin-top: 6px;
}

iframe {
  z-index: 1000;
}
</style>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>
